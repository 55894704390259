import { fetchAppData, fetchTPASettings } from '@wix/communities-blog-client-common';

import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { setLocale, setQueryLocaleAction } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { getInstance } from '../../common/controller/helpers';
import fetchCategories from '../../common/store/categories/fetch-categories';
import { fetchPublicationsCount } from '../../common/store/blog-stats/blog-stats-actions';
import { fetchCategoryWidgetInitialData } from '../actions/fetch-initial-data';
import { get } from 'lodash';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  translationsName,
  context = {},
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  const isCategoryWidgetAdapterExperimentEnabled = get(appParams, 'baseUrls.useCategoryWidgetAdapter') === 'true';
  if (isCategoryWidgetAdapterExperimentEnabled) {
    await Promise.all([
      store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber })),
      store.dispatch(setAppConfig({ bundleName })),
      store.dispatch(setAppSettings({ style: config.style.styleParams })),
      store.dispatch(fetchAppData()), // TODO: add to frontend adapter
      store.dispatch(fetchTPASettings({ language })), // TODO: add to frontend adapter
      store.dispatch(fetchCategoryWidgetInitialData()),
    ]);

    await store.dispatch(fetchTopology(instanceId));
    return;
  }

  await Promise.all([
    store.dispatch(fetchAppData()),
    store.dispatch(fetchExperiments(context.experimentsPromise)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(fetchTPASettings({ language })),
    store.dispatch(fetchTranslations(language, translationsName)),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
  ]);

  await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
  await store.dispatch(setAppConfig({ bundleName }));

  await Promise.all([store.dispatch(fetchCategories()), store.dispatch(fetchPublicationsCount())]);
}
